import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import * as React from "react";
import infoFile from "../assets/data/infoplainCards2.json";
import RoundedPlainCardsBlock from "../container/RoundedPlainCardsBlock";

const RightInfoBanner = () => {
  return (
    <Grid
      container
      sx={{
        flexDirection: { xs: "column-reverse", md: "row" },
        flexWrap: "nowrap",
        alignItems: { md: "center" },
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        justifyContent={"flex-start"}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Grid
          item
          xs={12}
          justifyContent={"flex-start"}
          style={{ display: "flex", flexDirection: "column" }}
          sx={{ padding: { xs: "0 20px", md: "0 5rem" } }}
        >
          <Typography
            sx={{ display: { xs: "none", md: "block" } }}
            style={{
              marginLeft: "4px",
              textAlign: "left",
              marginTop: "20px",
              paddingBottom: "5px",
            }}
            variant="h4"
            align="center"
          >
            Conéctate a la primera comunidad solar de Repsol
          </Typography>
          <Typography
            sx={{
              marginBottom: "0px",
              marginLeft: "4px",
              textAlign: "left",
              paddingBottom: "15px",
              paddingTop: { xs: "15px", md: 0 },
            }}
            variant="h3"
            align="center"
          >
            Consume energía solar
            <br /> a 0 céntimos €/kwh para siempre
          </Typography>
          <RoundedPlainCardsBlock infoPlainCards={infoFile} />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} sx={{ marginTop: { xs: "40px", sm: "0" } }}>
        <Typography
          sx={{ display: { md: "none" } }}
          style={{ textAlign: "left", padding: "0 20px" }}
          variant="h4"
          align="center"
        >
          Conéctate a la primera comunidad solar de Repsol
        </Typography>
        <picture>
          <source
            media="(max-width: 500px)"
            srcSet={require("../assets/images/rightinfo-banner-mobile.jpg")}
          />
          <img
            width="100%"
            height="100%"
            src={require("../assets/images/rightinfo-banner.jpg")}
            alt="banner"
            style={{
              display: "block",
              position: "relative",
              objectFit: "cover",
              objectPosition: "right",
            }}
          />
        </picture>
      </Grid>
    </Grid>
  );
};

export default RightInfoBanner;
