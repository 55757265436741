import {Typography, Stack, Grid, Container} from '@mui/material';
import HeaderMenuDDI from '../header/HeaderMenuDDI';
import { useLocation } from "react-router-dom";
import HeaderBanner from '../banner/HeaderBanner';
import Footer from '../footer/Footer';
import ComplexCardsBannerFullWidth from '../card/ComplexCardsBannerFullWidth';
import QuestionsHome from '../questions/QuestionsHome';
import StickyContainer from '../container/StickyContainer';
import StickyC2CBottom from '../button/StickyC2CBottom';
import { useDDI } from '../utils/GetDDI';
import LeftInfoBannerC2C from '../card/LeftInfoBannerC2C';
import StickyFormMobile from '../button/StickyFormMobile';
import RightInfoBanner from '../card/RightInfoBanner';

function Home() {
  const ddi = useDDI();
  const { search } = useLocation();
  return (
    <>
      <Stack>
        <StickyFormMobile/>
        <StickyContainer>
          <Container maxWidth="false" disableGutters>
            <HeaderMenuDDI link={`/${search}`} ddi={ddi} />
          </Container>
        </StickyContainer>
        <Container maxWidth="false" disableGutters>
          <HeaderBanner />
        </Container>
      {/*  <Container maxWidth="false" disableGutters sx={{margin: {xs: '10px' 0px, sm:'60px 0px'}}}>
          <CardsImages />
        </Container>*/}
        <Container maxWidth="false" disableGutters sx={{margin: {xs: '10px 0px', sm:'60px 0px'}, marginTop:{xs:'20px',sm:'120px'}}}>
          <ComplexCardsBannerFullWidth />
        </Container>
        <Container sx={{ margin: {xs: '10px 0px', sm:'60px 0px'} }} maxWidth="false" disableGutters>
          <LeftInfoBannerC2C ddi={ddi}/>
        </Container>
        <Container sx={{ margin: {xs: '10px 0px', sm:'60px 0px'} }} maxWidth="false" disableGutters>
          <RightInfoBanner />
        </Container>
        <Container maxWidth="false" disableGutters  style={{ marginBottom: '60px' }} sx={{ padding:{xs: '0 20px', md:'0 5rem'}}}>
          <Typography sx={{marginTop: '40px', marginBottom: '30px', marginRight: { sm:'10%'}, textAlign: 'left' }} variant="h3" align="center" >Resolvemos tus dudas</Typography>
          <QuestionsHome />
        </Container>
      </Stack>
      <StickyC2CBottom />
      <Stack sx={{ marginBottom: {md:'120px'} }}>
        <Footer />
      </Stack>
    </>
  );
}

export default Home;
