import * as React from 'react';
import Grid from '@mui/material/Grid';
import {makeStyles} from "@mui/styles";
import { Typography, List, ListItem } from '@mui/material';
import C2CButton from '../button/C2CButton';
import RoundedPlainCardsBlock from '../container/RoundedPlainCardsBlock';
import infoFile from '../assets/data/infoplainCards1.json'
const useStyles = makeStyles({
  listBanner: {
    '& li':{
      margin: '1rem 0'
    },
  },
});
const LeftInfoBannerC2C = ({ddi}) => {
  const classes = useStyles();

  const bullets = [ "Ahorra en tu segunda vivienda", "2 primeros meses totalmente gratis", "Almacenamiento ilimitado en batería" ]
  return (
    <Grid container>
      <Grid item xs={12} md={5} sx={{ marginTop: {xs:'40px', sm:'0'} }}>
        <picture>
          <source media="(max-width: 500px)" srcSet={require('../assets/images/leftinfo-banner-mobile.jpg')} />
          <img width="100%" height="100%" src={require('../assets/images/leftinfo-banner.jpg')} alt="banner"  style={{display: 'block', position: 'relative', objectFit: 'cover', objectPosition: 'right'}} />
        </picture>
      </Grid>
      <Grid item xs={12} md={7} justifyContent={"flex-start"} style={{display: 'flex', flexDirection:'column', backgroundColor:'white'}}>
        <Grid item  justifyContent={"center"} style={{display: 'flex', flexDirection:'column'}} sx={{paddingRight:{sm:'2rem!important'}, padding: {xs: '0 10px', sm:'0'},  marginLeft:{xs: '0rem', sm:'20px', md:'5rem'}}}>
          <Typography style={{marginTop: '20px',  marginLeft: '4px', textAlign:'left' }} variant="h3" align="center" >Baterías virtuales, la tarifa Solar para dejar tus facturas a 0€</Typography>
          <Typography style={{marginTop: '10px',  marginLeft: '4px', textAlign:'left'}} variant="h4" align="center" >Dales uso a tus excedentes de energía Solar y accede a ahorros exclusivos con nuestra batería virtual</Typography>
          <Typography style={{marginTop: '20px',  marginLeft: '4px', textAlign:'left' }} variant="h4" align="center" >Ventajas únicas con Repsol Solar:</Typography>
          <List sx={{width:{xs:'100%', md:'60%'}}}>
            {bullets.map((bullet, i) =>
              <ListItem key={i}  style={{ marginLeft:'4px', textAlign: 'left', color:'#464646', fontFamily:'Roboto, Arial', fontWeight:'300', gap:'8px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="19.908" height="14.846" viewBox="0 0 19.908 14.846">
                  <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M6.762,19.131l-6.47-6.47a1,1,0,0,1,0-1.408L1.7,9.846a1,1,0,0,1,1.408,0L7.466,14.2,16.8,4.869a1,1,0,0,1,1.408,0l1.408,1.408a1,1,0,0,1,0,1.408L8.169,19.131A1,1,0,0,1,6.762,19.131Z" transform="translate(0 -4.577)" fill="#00859b"/>
                </svg>
                <Typography style={{fontWeight:300}}>{bullet}</Typography>
              </ListItem>
            )}
          </List>
          <RoundedPlainCardsBlock infoPlainCards={infoFile}/>
          <Grid item xs={12} sm={3} justifyContent={"flex-start"} style={{display: 'flex', flexDirection:'column', marginLeft: '4px'}} sx={{marginTop: {xs: '0', sm:'10px'}}}>
            <C2CButton ddi={ddi} buttonText={"Llámame gratis"} />
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  );
}

export default LeftInfoBannerC2C;
